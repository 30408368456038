/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { withPrefix, Link, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import Table from '../../components/table';
import SummaryCard from '../../components/summary-card';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import Tips from '../../components/tips';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';
import { checkNull } from '../../helpers/check-null';
import { formatNumber } from '../../helpers/helpers';

const FixedRateMortgage = (props) => {
    const { getShareLink } = withShareResults();
    const PageContent = HTMLContent || Content;
	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    // React Redux hooks
	const dispatch = useDispatch();
    const query = useSelector(state => state.fixedRateMortgage);

    useEffect(() => {
        dispatch({ type: 'APPLY_FIXED_MORTGAGE_URL_PARAMS' });

        return () => {
            dispatch({ type: 'RESET_FIXED_MORTGAGE_SELECTIONS' });
        };
    }, []);

	// Interest rate per payment (months)
	const interestPerMonth = checkNull(query.interest) / 12 / 100;

	// Total number of payments (months)
	const totalPayments = checkNull(query.period) * 12;

	// Monthly repayment amount
	const calcMonthlyRepayment = query.amount && query.interest && query.period ? checkNull(query.amount) * ((interestPerMonth * Math.pow(1 + interestPerMonth, totalPayments)) / (Math.pow(1 + interestPerMonth, totalPayments) - 1)) : 0;

	// Total amount to be repaid
	const calcTotalRepayable = calcMonthlyRepayment * 12 * checkNull(query.period);
	
	// Total amount remaining after n years
	const calcTotalRemainingPerYear = (n) => {
		let result = (checkNull(query.amount) * Math.pow(1 + interestPerMonth, n)) - (calcMonthlyRepayment * ((Math.pow(1 + interestPerMonth, n) - 1) / interestPerMonth));
		return result;
	};
	
	// Array of table rows for yearly breakdown
	let yearlyBreakdownRow = [];

    yearlyBreakdownRow.push(
        <tr>
            <td>Starting balance</td>
            <td>{formatNumber(checkNull(query.amount))}</td>
            <td>-</td>
        </tr>
    );
	
	for (let i = 0; i < query.period; i++) {
        const previousYear = calcTotalRemainingPerYear((i * 12));
        const currentYear = calcTotalRemainingPerYear(((i+1) * 12));

		yearlyBreakdownRow.push(
			<tr key={i}>
				<td>{i+1} year{i+1 > 1 ? 's' : ''}</td>
				<td>{formatNumber(currentYear)}</td>
                <td>{i === 0 ? formatNumber(checkNull(query.amount) - currentYear) : formatNumber(previousYear - currentYear)}</td>
			</tr>
		);
	}

	// Calculate how much paid back per pound borrowed
	const payRate = formatNumber(checkNull(calcTotalRepayable / query.amount));

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />

            <PageHeader
                heading={pageInfo.frontmatter.title}
                imageSource={`${withPrefix("/")}img/${headerImage}`}
                modalContent={(
                    <PageContent content={pageInfo.html} />
                )}
                description={pageInfo.frontmatter.description}
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Property', url: '/property'},
                ]}
            />
            
            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id="amount"
                                required
                                labelText='Total Mortgage'
                                modalHeading={definitions[2].frontmatter.title}
                                modalContent={<PageContent content={definitions[2].html} />}
                                placeholder="0"
                                symbol='currency'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_FIXED_MORTGAGE_AMOUNT',
                                    payload: event.target.value,
                                })}
                                value={query.amount}
                            />

                            <InputField
                                id="interest"
                                required
                                labelText='Interest rate'
                                modalHeading={definitions[0].frontmatter.title}
                                modalContent={<PageContent content={definitions[0].html} />}
                                placeholder="0"
                                symbol='percentage'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_FIXED_MORTGAGE_INTEREST',
                                    payload: event.target.value,
                                })}
                                value={query.interest}
                            />

                            <InputField
                                id="period"
                                required
                                labelText='Repayment period (years)'
                                modalHeading={definitions[1].frontmatter.title}
                                modalContent={<PageContent content={definitions[1].html} />}
                                placeholder="0"
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_FIXED_MORTGAGE_PERIOD',
                                    payload: event.target.value,
                                })}
                                value={query.period}
                            />
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>

            <Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
            >
                <ResultListing>
                    <ResultListing.Item
                        heading='Monthly repayment'
                        result={`${formatNumber(checkNull(calcMonthlyRepayment))}`}
                    />

                    <ResultListing.Item
                        heading='Total repayable'
                        result={`${formatNumber(checkNull(calcTotalRepayable))}`}
                        modalHeading={definitions[3].frontmatter.title}
                        modalContent={<PageContent content={definitions[3].html} />}
                    />

                    <ResultListing.Item
                        heading='Amount repaid'
                        result={`${payRate}`}
                        subheading='per £1 borrowed'
                    />
                </ResultListing>

                {(checkNull(query.amount) !== 0 && checkNull(query.interest) !== 0 && checkNull(query.period) !== 0) && (
                    <SummaryCard heading='Yearly repayment breakdown'>
                        <Table describedBy='results-table-heading'>
                            <Table.Header>
                                <th>Time elapsed</th>
                                <th>Mortgage remaining</th>
                                <th>Repaid this year</th>
                            </Table.Header>
                            <tbody>
                                {yearlyBreakdownRow}
                            </tbody>
                        </Table>
                    </SummaryCard>
                )}

                <div className='summary-card__controls'>
                    <ShareResults url={getShareLink(query)} />
                </div>
            </Section>

            <Tips text={(
				<>
					<h2><span role='img' aria-hidden='true'>&#x1F3E0;</span> Buying your next property?</h2>
					<p>Find out how much Stamp Duty you'll pay on your next property purchase with our <Link to="/property/stamp-duty">Stamp Duty Calculator</Link></p>
				</>
			)} />
        </Default>
    );
};

export default FixedRateMortgage;

export const FixedRateMortgageQuery = graphql`
    query FixedRateMortgageQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                image {
                    relativePath
                }
                description
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/total-mortgage|src/definitions/mortgage-repayment-period|src/definitions/initial-interest-rate|/src/definitions/total-repayable/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;