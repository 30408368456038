import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import '../../sass/components/table.scss';

const Table = ({
    className,
    variant,
    id,
    caption,
    children,
    describedBy,
    style,
}) => {
    // Class definitions
    const baseClass = 'table';
    const variantClass = variant ? `table--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <div className={classes} id={id} style={style}>
            <table className="table__container" aria-describedby={describedBy}>
                {caption && (
                    <caption className="table__caption" htmlFor={id}>
                        {caption}
                    </caption>
                )}
                {children}
            </table>
        </div>
    );
};

Table.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    id: PropTypes.string,
    caption: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.object,
};

Table.Header = ({
    children,
    className,
}) => (
    <thead className={className}>
        <tr>
            {children}
        </tr>
    </thead>
);

Table.Header.propTypes = {
    children: PropTypes.node,
};

Table.Footer = ({
    children,
}) => (
    <tfoot>
        {children}
    </tfoot>
);

Table.Footer.propTypes = {
    children: PropTypes.node,
};

export default Table;
