/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import InfoModal from '../info-modal';

// Style Imports
import '../../sass/components/summary-card.scss';

const SummaryCard = ({
    className,
    heading,
    select,
    selectID,
    selectOnChange,
    selectValue,
    selectDefaultValue,
    children,
    tooltipHeading,
    tooltipContent,
}) => {
    // Class definitions
    const baseClass = 'summary-card';
    const customClass = className;
    const classes = [baseClass, customClass].filter(Boolean).join(' ');

    return (
        <section className={classes}>
            {tooltipContent ? (
                <div className='summary-card__heading-wrapper'>
                    {heading && (
                        <h2 className='summary-card__heading'>{heading}</h2>
                    )}

                    <InfoModal heading={tooltipHeading}>
                        {tooltipContent}
                    </InfoModal>
                </div>
            ) : (
                <>
                    {heading && (
                        <h2 className='summary-card__heading'>{heading}</h2>
                    )}
                </>
            )}

            {select && (
                <div className='summary-card__select'>
                    <label className='summary-card__label' htmlFor={selectID}>Filter results:</label>

                    <div className='summary-card__input-wrapper'>
                        <select
                            className="summary-card__input"
                            id={selectID}
                            name={selectID}
                            onChange={selectOnChange}
                            value={selectValue}
                            defaultValue={selectDefaultValue}
                        >
                            {select.map((option, i) => (
                                <option value={option.value} key={i}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            <div className='summary-card__content'>
                {children}
            </div>
        </section>
    );
};

SummaryCard.defaultProps = {
    selectDefaultValue: 'year',
};

SummaryCard.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    select: PropTypes.array,
    selectID: PropTypes.string,
    selectOnChange: PropTypes.func,
    selectValue: PropTypes.string,
    selectDefaultValue: PropTypes.string,
    children: PropTypes.node,
    tooltipHeading: PropTypes.string,
    tooltipContent: PropTypes.node,
};

export default SummaryCard;
